<template>
  <section class="views">
    <!-- header -->
    <headers></headers>
    <!-- banner  -->
    <div class="posters">
      <van-image
        width="100%"
        :src="require('@/assets/image/hodgepodge/pcbanner.png')"
      />
      <div class="posters_ewm">
        <div class="ewm" @click="whatapp()">
          <div class="ewm2">
            <div class="ewm3">
              <img :src="require('@/assets/image/hodgepodge/ewm.png')" alt="" />
            </div>
            <div class="ewm3_value">
              <img :src="require('@/assets/image/wa.png')" alt="" /> 人工諮詢
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 举办方 -->
    <div class="organizers">
      <div class="box_uls">
        <div class="box_title">
          <img
            :src="require('@/assets/image/hodgepodge/pctitle1.png')"
            alt=""
          />
        </div>
        <div class="box_centent zhuban">
          <div class="xiaoIconBox">
            <div
              v-for="(item, index) in sponsorIcon"
              :key="index"
              class="xiaoIconList"
            >
              <img :src="item" alt="" />
            </div>
          </div>

          <div class="posters_text">
            <ul>
              <li v-for="(item, index) in sponsorArr" :key="index">
                <div class="posters_text_left">{{ item.leftValue }}</div>
                <div class="posters_text_right">{{ item.rightValue }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 比赛类型Tab -->
    <div class="competition-types">
      <div class="type-tabs">
        <div
          v-for="item in competitionType"
          :key="item.value"
          @click="changeCompetition(item.value)"
          :class="{ active: item.value === currentCompetition }"
          class="tab-item"
        >
          {{ item.title }}
        </div>
      </div>
    </div>

    <!-- 時間軸 -->
    <div class="TimeAxis">
      <!-- 時間軸上面文字 -->
      <ul>
        <div class="time_xian_top">
          <li
            v-for="(item, index) in competitionTimeLine"
            :key="index"
            @click="stageBut(index)"
          >
            <div
              :class="item.select ? 'select' : 'cshhSelect'"
              v-if="!item.select"
            >
              {{ item.name }}
            </div>
            <img
              :src="require('@/assets/image/qp/' + (item.index || index + 1) + '.png')"
              alt=""
              v-else
            />
          </li>
        </div>
      </ul>
      <!-- 時間軸 -->
      <ul>
        <div class="time_xian">
          <li
            v-for="(item, index) in competitionTimeLine"
            :key="index"
            @click="stageBut(index)"
          >
            <div class="xian">
              <span
                v-if="item.xuanSelect"
                :class="item.xuanSelect ? 'select' : ''"
                >{{ item.xuanSelect ? "當前階段" : "" }}</span
              >

              <span v-else :class="item.select ? 'span_t' : ''"></span>
            </div>
          </li>
        </div>
      </ul>
      <!-- 時間軸下面文字 -->
      <ul>
        <div class="time_xian_timeBox">
          <li
            v-for="(item, index) in competitionTimeLine"
            :key="index"
            v-html="item.tiem"
            @click="stageBut(index)"
          ></li>
        </div>
      </ul>
      <!-- 時間軸 -->
      <ul>
        <div class="time_xian_fuchuang">
          <li v-for="(item, index) in competitionTimeLine" :key="index">
            <template v-if="item.select">
              <div class="list">
                <h1>{{ item.content.title }}</h1>
                <div class="p_xian" v-html="item.content.value"></div>
                <div
                  class="butom"
                  v-if="item.xuanSelect && index == 0 && currentCompetition === '1'"
                  @click="aheftBut()"
                >
                  <!-- 報名尚未開始 -->
                  <!-- 在線報名 -->
                  <div class="buttomBut">在線報名</div>
                </div>
              </div>
            </template>
          </li>
        </div>
      </ul>
    </div>

    <!-- 参赛资格 -->
    <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/cszg.png')" alt="" />
        </div>
        <div class="box_centent cszg2">
          <div class="cszg2_list">
            <ul>
              <li
                v-for="(item, index) in competitionQualifying.info"
                :key="index"
                v-html="item"
              ></li>
            </ul>
          </div>
          <div class="cszg2_uls">
            <div
              v-for="(item, index) in competitionQualifying.group"
              :key="index"
              class="cszg2_uls_list"
            >
              {{ item.name }}
              <div class="select_bz">{{ item.level }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 報名確定 -->
    <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/csxz.png')" alt="" />
        </div>
        <div class="box_centent bxxq">
          <div class="bxxq_title">報名方法</div>

          <div class="bxxq_centent">
            <ul v-if="this.currentCompetition === '1'">
              <li>
                <span>參賽者個人</span
                >需要在<strong>2025年5月10日21:00前</strong>使用本次活動之官方活動網站或中文路App
                程式：ChineseRd Plus完成報名。
              </li>
              <li>
                <span>學校/機構</span
                >統一報名需要在<strong>2025年5月10日21:00前</strong>使用本次活動之<span>官方活動網站</span>完成報名。
              </li>
              <li>
                <span>非華裔學生（飛躍組）、特殊學校（閃亮組）</span
                >請通過學校老師進行統一報名，需在<strong>2025年5月10日21:00前</strong>使用本次比賽之官方活動網站完成報名。
              </li>
              <li>
                官方活動網站報名操作指引
                <!--  @click="handleShowGuide('video')" -->
                <span class="show-link" @click="handleShowGuide('video', 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/speech_2025/5.mp4')">（視頻1：學校/機構報名）</span>
              </li>
              <li>
                中文路App程式：<a href="https://app.chineserd.com/" target="_blank" class="show-link">ChineseRd Plus App 下載</a>
              </li>
              <li>
                同一電話號碼僅可用於2-3位參賽者報名（只適用於一個家庭當中有多位參賽者）。
              </li>
              <!-- <li>參賽者所填報的姓名必須與香港身份證/出生證明書相同。</li> -->
            </ul>
            <ul v-else>
              <li>
                <span>學校/非牟利機構</span>需在<strong>2025年6月8日21:00前</strong>使用本次活動之<span>官方活動網站</span>完成報名。
              </li>
              <li>
                官方活動網站報名操作指引<span class="show-link" @click="handleShowGuide('video', 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/speech_2025/4.mp4')">（視頻1：學校/機構團體賽報名）</span>
              </li>
            </ul>
          </div>

          <div class="bxxq_title">報名確認</div>
          <div class="bxxq_centent">
            <ul v-if="this.currentCompetition === '1'">
              <li>
                當中文路收妥報名表並審核無誤後，3個工作天內便會向參賽者發出報名成功確認電郵。參賽者應細閱電郵內的一切注意事項。
              </li>
              <!-- <li>
                App報名與參賽指引：<span
                  class="show-link"
                  @click="handleShowGuide('video')"
                  >報名操作指引影片（點擊查看）</span
                >、<span class="show-link" @click="handleShowGuide('img')"
                  >初賽操作指引圖片（點擊查看）</span
                >
              </li> -->
              <li>
                請報名的參賽者時刻留意主辦機構的聯絡資訊（包括但不限於：SMS短信、E-mail郵件、電話或WhatsApp等），如錯過主辦方發出的通知資訊所導致的問題主辦方概不負責。
              </li>
            </ul>
            <ul v-else>
              <li>當中文路收妥報名表並審核無誤後，3個工作天內便會向參賽者發出報名成功確認電郵。參賽者應細閱電郵內的一切注意事項。</li>
              <li>請報名的聯絡人時刻留意主辦機構的聯絡資訊（包括但不限於：SMS短信、E-mail郵件、電話或WhatsApp等），如錯過主辦方發出的通知資訊所導致的問題主辦方概不負責。</li>
            </ul>
          </div>

          <div class="bxxq_title">注意事項</div>
          <div class="bxxq_centent" style="margin-bottom: 8px">
            <ul v-if="this.currentCompetition === '1'">
              <li>
                所有比賽階段的表演者必須為參賽者本人，如審核發現非參賽者本人錄製或出席現場比賽之情況，將被取消參賽資格，予以失格公示。
              </li>
              <li>
                凡是報名比賽的參賽者，等同於同意主辦方將其參賽影片使用在不同渠道媒體進行展示宣傳。
              </li>
            </ul>
            <ul v-else>
              <li>凡是報名比賽的參賽者，等同於同意主辦方將其參賽影片使用在不同渠道媒體進行展示宣傳。</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 比賽階段 -->
    <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/bsjd.png')" alt="" />
        </div>
        <div class="box_centent cszg">
          <div class="cszglis">
            <div class="cszg_title">第一階段：初賽</div>
            <div class="cszg_centent">
              <template v-if="this.currentCompetition === '1'">
                <span>初賽</span>報名人數不限。各組參賽者需使用官方指定誦材，及指定中文路App進行普通話語音測評。<br />
                各組別分數排名<span style="color: #e80202">前100名</span>將獲得複賽資格，若出現同分同名次情況，可同時進入複賽。<br/>
                <span class="show-link" @click="handleShowGuide('video', 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/speech_2025/3.mp4')">APP-個人學生初賽操作指引視頻</span><br/>
                <span class="show-link" @click="handleShowGuide('video', 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/speech_2025/2.mp4')">APP-學校/機構學生初賽操作指引視頻</span>
              </template>

              <template v-else>
                <span>初賽</span> 階段，中、小學及幼稚園各組別隊伍可使用官方推薦篇目或自選誦材錄製集誦影片，演出時間上限為 6 分鐘，逾時扣分，時間將按影片長度計算。<br />
                所有提交初賽影片的隊伍將獲發電子參賽證書。<br />
                各組別按分數排名，<span style="color: #e80202">前3隊</span>將晉級現場決賽，若同分同名次，可同時晉級。<br />
                各組別分數排名第4至10名的隊伍將獲優異獎。
              </template>
            </div>

            <template v-if="this.currentCompetition === '1'">
              <div class="cszg_title">第二階段：複賽</div>
              <div class="cszg_centent">
                <span>複賽</span>人數為每組排名
                <span style="color: #e80202">前100名</span>
                各組參賽者需使用官方指定誦材，錄製朗誦影片，並通過官方活動網站或中文路App提交影片。<br />
                各組別按分數排名，<span style="color: #e80202">前6名</span>將進入決賽，若同分同名次，可同時晉級決賽。<span style="color: #e80202">第7名至第10名</span>將獲優異獎。
              </div>
              <div class="cszg_title">第三階段：決賽暨頒獎典禮</div>
              <div class="cszg_centent">
                <span>決賽</span>人數為每組6人，共8組，總人數為48人。參賽者須使用官方指定誦材，按順序進行<span>現場對決比賽</span>，並會同步錄製對決比賽過程。<br />
                各組各自評出<span>金獎1名、銀獎2名、銅獎3名</span>。
              </div>
            </template>
            <template v-if="this.currentCompetition === '2'">
              <div class="cszg_title">第二階段：決賽</div>
              <div class="cszg_centent">
                <span>決賽</span><span style="color: #e80202">共12隊</span>。現場進行對決，各組別隊伍可使用官方推薦篇目或自選誦材進行朗誦，演出時間上限為 6 分鐘，逾時將扣分。比賽全程同步錄製。<br />
                各組將評出金獎1隊、銀獎1隊、銅獎1隊。
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- 比賽詳情 -->
    <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/bsxq.png')" alt="" />
        </div>
        <div class="box_centent bxxq">
          
          <div class="bxxq_title">比賽誦材</div>
          <div class="bxxq_centent">
            <ul v-if="this.currentCompetition === '1'">
              <li>
                中文路App
                將新增比賽誦材練習板塊，請各位參賽者選擇對應組別的誦材進行練習，注意練習板塊的成績不會計入比賽成績。
              </li>
              <li>幼稚園組、飛躍組、閃亮組 在初賽-複賽-決賽階段不更換誦材。</li>
              <li>
                小學組、初中組 在複賽階段將更換新誦材，複賽與決賽使用同一誦材。
              </li>
            </ul>
            <ul v-else>
              <li>比賽官方提供新詩、散文、古詩等推薦篇目。</li>
              <li>自選誦材必須符合「誦華夏經典，傳中華文化」主題，如古典或現代詩詞，並鼓勵創新演繹。注：自選誦材內容須提前提交審核。</li>
              <li>初賽與決賽可選用同一篇誦材。</li>
            </ul>
            <div class="a_view" @click="socai()">點擊下載第四屆比賽誦材</div>
          </div>

          <div class="bxxq_title">比賽章程</div>
          <div class="bxxq_centent">
            <ul>
              <li>詳細的「比賽章程」請點擊下方鏈接</li>
            </ul>
            <div class="a_view" @click="zhangcheng()">
              點擊下載第四屆比賽章程
            </div>
          </div>

          <div class="bxxq_title">評分標準</div>
          <div v-if="this.currentCompetition === '1'" class="score-box">
            <div class="left-title">初賽</div>
            <table class="table-wrap">
              <thead>
                <tr>
                  <th style="width: 26%;">評判標準</th>
                  <th>內容説明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="3">語音（75%）</td>
                  <td style="text-align: left;">聲調（25%）</td>
                </tr>
                <tr>
                  <td style="text-align: left;">聲母（25%）</td>
                </tr>
                <tr>
                  <td style="text-align: left;">韻母（25%）</td>
                </tr>
                <tr>
                  <td>流暢（20%）</td>
                  <td style="text-align: left;">流暢度（20%）</td>
                </tr>
                <tr>
                  <td>完整（5%）</td>
                  <td style="text-align: left;">完整度（5%）：完整誦讀指定篇目</td>
                </tr>
              </tbody>
            </table>

            <div class="left-title">複賽及決賽</div>
            <table class="table-wrap">
              <thead>
                <tr>
                  <th style="width: 26%;">評判標準</th>
                  <th>內容説明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="2">語音（30分）</td>
                  <td style="text-align: left;">普通話發音（20分）：聲母、韻母、聲調、吐字清晰。每處錯誤扣0.5或1分。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">節奏韻律（10分）：語速恰當、斷句準確、停連得當。每處錯誤扣0.5或1分。</td>
                </tr>
                <tr>
                  <td rowspan="3">儀態面貌（10分）</td>
                  <td style="text-align: left;">服飾大方得體（3分）</td>
                </tr>
                <tr>
                  <td style="text-align: left;">舉止從容，大方有感染力（3分）</td>
                </tr>
                <tr>
                  <td style="text-align: left;">頭尾問好、答謝（4分）</td>
                </tr>
                <tr>
                  <td rowspan="6">誦材處理（60分）</td>
                  <td style="text-align: left;">肢體動作（10分）：配以適當的動作和手勢或增加創意部分，完全無動作或肢體語言扣5分。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">感情處理（15分）：富有感情、有感染力、充分表達誦材的情感、抑揚頓挫、渲染氣氛。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">表現力（15分）：聲音洪亮、眼神、表情準確自然、活潑生動。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">內容把握（10分）：誦材基調把握到位、傳遞正確主旨。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">誦材完成度（10分）：表演自然流暢，文章熟悉，全文背誦。</td>
                </tr>
              </tbody>
            </table>

            <div class="table-tips">
              *最終評分解釋權為比賽評審團所屬。
            </div>
          </div>
          <div v-if="this.currentCompetition === '2'" class="score-box">
            <div class="left-title">初賽</div>
            <table class="table-wrap">
              <thead>
                <tr>
                  <th style="width: 26%;">評判標準</th>
                  <th>內容説明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="2">語音（20分）</td>
                  <td style="text-align: left;">普通話發音（10分）：聲母、韻母、聲調、吐字清晰。每處錯誤扣0.5或1分。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">節奏韻律（10分）：語速恰當、斷句準確、停連得當。每處錯誤扣0.5或1分。</td>
                </tr>
                <tr>
                  <td rowspan="2">合作性（15分）</td>
                  <td style="text-align: left;">服裝整齊、精神飽滿、舉止從容大方（5分）</td>
                </tr>
                <tr>
                  <td style="text-align: left;">聲音整齊度（10分）：領誦、聲部間及合誦等接連流暢自然而有默契。</td>
                </tr>
                <tr>
                  <td rowspan="4">誦材處理（55分）</td>
                  <td style="text-align: left;">肢體語言（5分）：配以適當的動作和手勢，舒展自然，不過分誇張。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">感情處理（25分）：富有感情、有感染力、充分表達誦材的情感、抑揚頓挫、添加重音渲染氣氛。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">表現力（15分）：眼神、表情準確自然、活潑生動，富有張力並符合文意。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">内容把握（10分）：基調準確，傳達正確主旨，語調或語氣運用配合誦材內容的態度或感情。</td>
                </tr>
                <tr>
                  <td>創新（10分）</td>
                  <td style="text-align: left;">
                    聲音變化：聲部組合、停頓、節奏轉換等有出乎意料之外，合乎情理之中的處理方法，聽覺上予人新鮮感。<br />
                    配樂合理：不影響朗誦前提下，配以恰當背景音樂。<br />
                    隊型設計：畫面美觀，安排合理，有效暗示或反映誦材的重要內容。
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="left-title">決賽</div>
            <table class="table-wrap">
              <thead>
                <tr>
                  <th style="width: 26%;">評判標準</th>
                  <th>內容説明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>語音（10分）</td>
                  <td style="text-align: left;">
                    普通話發音（10分）：<br />
                    聲母、韻母、聲調、吐字清晰。每處錯誤扣4分，若出現三次以上錯音，則全部扣完。</td>
                </tr>
                <tr>
                  <td rowspan="4">台風（35分）</td>
                  <td style="text-align: left;">
                    服裝（5分）：<br />
                    服裝整齊得體，大方美觀。
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left;">
                    肢體動作（10分）：<br/>
                    全體參與人員的動作整齊劃一，舒展自然，不過分誇張。
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left;">
                    隊形變化（10分）：<br/>
                    考察全體參與人員的默契度，畫面美觀，安排合理，有效暗示或反映作品的重要內容。
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left;">
                    感染力（10分）：<br/>
                    精神飽滿、舉止從容大方；<br />
                    全體參與人員的眼神、面部表情的配合，默契整齊，準確自然，富有張力並符合文意。
                  </td>
                </tr>
                <tr>
                  <td rowspan="4">誦材處理（55分）</td>
                  <td style="text-align: left;">
                    整齊流暢度（10分）：<br/>
                    領誦與聲部以及合誦等接連流暢自然而有默契；<br/>
                    聲音的變化，和諧、豐潤、洪亮、具層次感。
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left;">感情處理（20分）：<br/>
                    富有感情、有感染力、充分表達誦材的情感、抑揚頓挫、添加重音渲染氣氛。</td>
                </tr>
                <tr>
                  <td style="text-align: left;">
                    誦材的編排設計（20分）：<br/>
                    全體參與人員對作品的理解，基調準確，傳達正確主旨；<br/>
                    語調或語氣運用配合作品內容的態度或感情；<br/>
                    節奏韻律，語速恰當、斷句準確、停連得當；<br/>
                    對作品的設計編排有創意。
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left;">
                    整體創意（5分）：<br/>
                    聲部組合、停頓、節奏轉換等有出乎意料之外，合乎情理之中的處理方法，聽覺上予人新鮮感。
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="table-tips">
              *最終評分解釋權為比賽評審團所屬。
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="viewBoxContent" style="margin-bottom: 20px;">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/csjx.png')" alt="" />
        </div>

        <div class="awards-box">
          <table v-if="this.currentCompetition === '1'" class="table-wrap">
            <thead>
              <tr>
                <th style="width: 18%;">比賽階段</th>
                <th style="width: 18%;">獎項</th>
                <th>獎品內容</th>
                <th style="width: 26%;">選拔方式</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>初賽階段</td>
                <td>參賽證明</td>
                <td>電子-參賽證明</td>
                <td>完成App語音測試的參賽者</td>
              </tr>
              <tr>
                <td rowspan="3">複賽階段</td>
                <td>評分紙</td>
                <td>電子-評分紙</td>
                <td>完成複賽提交影片的參賽者</td>
              </tr>
              <tr>
                <td>優異獎<br /> （各組別4名）</td>
                <td>
                  ① 獎牌及獲獎證書；<br />
                  ② 1份學習禮包。<br />
                </td>
                <td>各組分數排名第7名-第10名</td>
              </tr>
              <tr>
                <td>入圍證明</td>
                <td>電子-入圍決賽證明</td>
                <td>入圍決賽48名參賽者</td>
              </tr>
              <tr>
                <td rowspan="3">決賽階段</td>
                <td>金獎<br /> （各組別1名）</td>
                <td>
                  ① 2,000港元現金；<br />
                  ② 獎盃及獲獎證書；<br />
                  ③ 1份學習禮包。<br />
                </td>
                <td rowspan="6">現場比賽</td>
              </tr>
              <tr>
                <td>銀獎<br /> （各組別2名）</td>
                <td>
                  ① 1,000港元現金； <br />
                  ② 獎盃及獲獎證書；<br />
                  ③ 1份學習禮包。<br />
                </td>
              </tr>
              <tr>
                <td>銅獎<br />（各組別3名）</td>
                <td>
                  ① 800港元現金；<br />
                  ② 獎盃及獲獎證書；<br />
                  ③ 1份學習禮包。<br />
                </td>
              </tr>
            </tbody>
          </table>
          <table v-if="this.currentCompetition === '2'" class="table-wrap">
            <thead>
              <tr>
                <th style="width: 16%;">
                  <strong>比賽階段</strong>
                </th>
                <th style="width: 20%;">
                  <strong>獎項</strong>
                </th>
                <th>
                  <strong>獎品內容</strong>
                </th>
                <th>
                  <strong>選拔方式</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="4">初賽階段</td>
                <td>隊伍參賽證明</td>
                <td>電子-參賽證明</td>
                <td rowspan="2">提交影片</td>
              </tr>
              <tr>
                <td>指導老師感謝狀</td>
                <td>電子-感謝狀</td>
              </tr>
              <tr>
                <td>隊伍入圍證明</td>
                <td>電子-入圍證明</td>
                <td>各組別分數排名<span style="color: #e80202">前3隊</span>入圍決賽</td>
              </tr>
              <tr>
                <td>隊伍優異獎</td>
                <td>
                  ① 隊伍優異獎證書；<br />
                  ② 金獎優秀指導老師證書；<br />
                  ③ 所屬隊伍參賽學生證書。
                </td>
                <td>
                  各組別分數排名第4-10名
                </td>
              </tr>
              <tr>
                <td rowspan="3">決賽階段</td>
                <td>
                  金獎<br />（1隊）
                </td>
                <td>
                  ①所屬團體學校/機構獎盃及證書；<br />
                  ② 金獎優秀指導老師證書；<br />
                  ③ 所屬隊伍參賽學生證書；<br />
                  ④ 隊伍學習禮包。
                </td>
                <td rowspan="3">現場比賽</td>
              </tr>
              <tr>
                <td>銀獎<br />（1隊）</td>
                <td>
                  ①所屬團體學校/機構獎盃及證書；<br />
                  ② 銀獎優秀指導老師證書；<br />
                  ③ 所屬隊伍參賽學生證書；<br />
                  ④ 隊伍學習禮包。
                </td>
              </tr>
              <tr>
                <td>銅獎<br />（1隊）</td>
                <td>
                  ①所屬團體學校/機構獎盃及證書；<br />
                  ② 銅獎優秀指導老師證書；<br />
                  ③ 所屬隊伍參賽學生證書；<br />
                  ④ 隊伍學習禮包。
                </td>
              </tr>
            </tbody>
          </table>
          <div class="table-tips">
            *主辦方保留對比賽流程安排及比賽相關章程的最終解釋權。如有任何爭議，主辦方保留一切最終決定權。
          </div>
        </div>
      </div>
    </div>

    <!-- 參賽奬項 -->
    <!-- <div class="viewBoxContent">
      <div class="box_uls">
        <div class="box_title">
          <img :src="require('@/assets/image/homeTitle/csjx.png')" alt="" />
        </div>
        <div class="box_centent csjx">
          <div class="csjx_centent">
            <div class="csjx_centent_view1 bo_color">
              <h1>金獎<span>(各組別1名)</span></h1>
              <ul>
                <li>2,000港元現金</li>
                <li>獎盃及獲獎證書</li>
                <li>1份學習禮包</li>
              </ul>

              <div class="jiangbei">
                <img
                  :src="require('@/assets/image/jiangbei/jinpei.png')"
                  alt=""
                />
              </div>
            </div>

            <div class="csjx_centent_view1 bo_color2">
              <h1>銀獎<span>(各組別2名)</span></h1>
              <ul>
                <li>1,000港元現金</li>
                <li>獎盃及獲獎證書</li>
                <li>1份學習禮包</li>
              </ul>

              <div class="jiangbei">
                <img
                  :src="require('@/assets/image/jiangbei/yinbei.png')"
                  alt=""
                />
              </div>
            </div>

            <div class="csjx_centent_view1 bo_color3">
              <h1>銅獎<span>(各組別3名)</span></h1>
              <ul>
                <li>800港元現金</li>
                <li>獎盃及獲獎證書</li>
                <li>1份學習禮包</li>
              </ul>

              <div class="jiangbei">
                <img
                  :src="require('@/assets/image/jiangbei/tongbei.png')"
                  alt=""
                />
              </div>
            </div>
            <div class="csjx_centent_view2">
              <div class="lists">
                <div>
                  <span style="font-weight: 600; font-size: 16px">優異獎</span
                  ><br />（各組別4名）
                </div>
              </div>
              <div class="lists2">
                <ul>
                  <li>獎牌及獲獎證書</li>
                  <li>1份學習禮包</li>
                </ul>
              </div>
            </div>

            <div
              style="
                margin-top: 20px;
                line-height: 25px;
                text-align: left;
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #e80202;
              "
            >
              *主辦方保留對比賽流程安排及比賽相關章程的最終解釋權。如有任何爭議，主辦方保留一切最終決定權。
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- 佔位 -->
    <!-- <div class="viewBoxContent"></div> -->
    <!-- <div class="uploadVideo" @click="handleUploadVideo()">上載影片</div> -->
    <!-- 复赛资料视频上传-->

    <van-popup
      style="margin-top: 30px"
      v-model="datumZZC"
      :close-on-click-overlay="false"
      :closeable="true"
      :round="true"
      :lock-scroll="false"
    >
      <div class="datumBox">
        <div class="titleH1">
          <div class="left_icon">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="title">上載參賽影片</div>
          <div class="right_icon">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div class="stepBox">
          <div class="stepUls">
            <div
              class="xian"
              :class="stepActive >= 1 ? 'active2' : 'active'"
            ></div>
            <div
              class="xian"
              :class="stepActive >= 2 ? 'active2' : 'active'"
            ></div>
          </div>

          <ul>
            <li
              v-for="(item, index) in 3"
              :class="stepActive >= index ? 'active' : ''"
              :key="index"
            ></li>
          </ul>
        </div>
        <div class="stepText">
          <div :class="stepActive >= 0 ? 'active' : ''">登錄</div>
          <div :class="stepActive >= 1 ? 'active' : ''">完善資料</div>
          <div :class="stepActive >= 2 ? 'active' : ''">上載影片</div>
        </div>

        <div class="datumCentent" v-if="stepActive == 0">
          <div class="tab">
            <div class="prompt" style="margin-bottom: 15px; cursor: pointer">
              <div
                :class="chooseFrom == 0 ? 'highlight' : ''"
                @click="datumYZ(0)"
              >
                參賽手機
              </div>
              <div
                :class="chooseFrom == 1 ? 'highlight' : ''"
                @click="datumYZ(1)"
              >
                參賽電郵
              </div>
            </div>
            <div class="promptInput2" v-if="chooseFrom == 0">
              <div class="lis">
                <div class="lisLife">
                  <!-- <img :src="require('@/assets/image/hk.png')" />
									<span>+852</span> -->
                  <CountryCodeSelector
                    :searchbar="false"
                    :countryCode.sync="prefix"
                    style="width: 100px; margin-right: 15px"
                  ></CountryCodeSelector>
                </div>
                <div class="lisRight">
                  <input
                    type="text"
                    placeholder="請輸入參賽電話號碼"
                    value=""
                    v-model.trim="from2.phone"
                  />
                </div>
              </div>
            </div>
            <div class="promptInput" v-if="chooseFrom == 1">
              <input placeholder="請輸入參賽郵箱" v-model.trim="from2.email" />
            </div>
            <div class="promptInput">
              <input placeholder="請輸入參賽者姓名" v-model="from2.name" />
            </div>
          </div>
          <div class="tabTis">
            提交遇到問題？點擊右側按鈕聯系我們
            <a href="http://wa.me/85291496563" target="_blank" @click="lianxi"
              ><img src="@/assets/image/fusai/whatsapp.png"
            /></a>
          </div>
          <div
            class="but"
            style="cursor: pointer"
            @click="datumActiveBut ? datumBut(0) : ''"
          >
            下一步
          </div>
        </div>

        <div class="datumCentent" v-if="stepActive == 1">
          <div class="tab">
            <div class="prompt" v-show="schollName == null">就讀學校</div>
            <div class="promptInput" v-show="schollName == null">
              <input placeholder="請輸入就讀學校" v-model="from2.school_name" />
            </div>
            <div class="prompt">
              證件上傳
              <span>&nbsp;帶照片學生證/身份證明</span>
            </div>
            <div class="promptInput3">
              <uploaderCent
                @uploaderChange="uploaderImageChange"
                accept="image/*"
                uploadText="點擊上傳"
                logoaAtive="0"
              ></uploaderCent>
            </div>
          </div>

          <div class="tabTis">
            提交遇到問題？點擊右側按鈕聯系我們
            <a href="http://wa.me/85291496563" target="_blank" @click="lianxi"
              ><img src="@/assets/image/fusai/whatsapp.png"
            /></a>
          </div>
          <div class="but" style="cursor: pointer" @click="datumBut(1)">
            下一步
          </div>
        </div>

        <div class="datumCentent" v-if="stepActive == 2">
          <div class="tab">
            <div class="promptInput3">
              <uploaderCent
                @uploaderChange="uploaderVideoChange"
                accept="video/*"
                uploadText="點擊上載影片"
                v-if="wonderful == false"
                logoaAtive="1"
              ></uploaderCent>
              <!-- 上傳影片成功后顯示預覽按鈕 -->
              <div class="videoYulan" v-if="videoYL">
                <div class="guanbu" style="cursor: pointer" @click="videoYLBut">
                  X
                </div>
                <video controls :autoplay="true" :src="videoUrl"></video>
              </div>
              <div
                class="videoYulan2"
                style="cursor: pointer"
                v-if="from2.video != null && wonderful == false"
                @click="videoYLBut"
              >
                <span style="color: #000000 !important; margin-right: 10px"
                  >影片已成功上載</span
                >點擊預覽視頻
              </div>
              <!-- 點擊預覽視頻  @click="videoYLBut"-->
              <!-- <div class="viewVideo" v-if="from2.video && wonderful == false">
                <div>影片已成功上載</div>
                <div
                  class="videoYulan2"
                  @click="videoYLBut"
                >
                  點擊預覽視頻
                </div>
              </div>
              <div v-if="from2.video && wonderful == false" class="reselect">重新选择</div> -->

              <!-- 提交成功 -->
              <div class="wonderful" v-if="wonderful == true">
                <img src="@/assets/image/fusai/ludui.png" />
                恭喜你，成功上載影片，請耐心等待評審結果
              </div>
            </div>
          </div>
          <div class="tabTis" v-if="wonderful == false">
            提交遇到問題？點擊右側按鈕聯系我們
            <a href="http://wa.me/85291496563" target="_blank" @click="lianxi"
              ><img src="@/assets/image/fusai/whatsapp.png"
            /></a>
          </div>
          <div
            class="but"
            style="cursor: pointer"
            v-if="wonderful == false"
            @click="datumActiveBut ? submit() : ''"
          >
            提交
          </div>
          <!-- <div class="but2" style="cursor: pointer;" @click="getBack()">返回上一步</div> -->
          <div
            class="but"
            style="cursor: pointer"
            v-if="wonderful == true"
            @click="wonderfulBut"
          >
            我知道了
          </div>
        </div>

        <!-- 未入圍/成功入園 -->
        <van-popup v-model="newShortZZC" :round="true" :lock-scroll="false">
          <div class="newShortBox">
            <div class="list" v-if="newShortActive == 2">
              <div class="ulst">
                <ul>
                  <li>姓名</li>
                  <li>組別</li>
                  <li>分數</li>
                </ul>
                <ul>
                  <li>
                    {{ userObj.studentName == null ? "" : userObj.studentName }}
                  </li>
                  <li>
                    {{
                      userObj.competitionGroup == null
                        ? ""
                        : userObj.competitionGroup
                    }}
                  </li>
                  <li>{{ userObj.score == null ? "" : userObj.score }}</li>
                </ul>
              </div>
              <div class="lis lisText">
                很遺憾，您的成績未能入圍複賽，非常感謝您的參與
              </div>
            </div>
          </div>
        </van-popup>
      </div>
    </van-popup>
    <van-dialog
      v-model="showGuide"
      :show-close-button="false"
      :close-on-click-overlay="true"
      width="80%"
      z-index="99999"
      confirm-button-text="關 閉"
      @close="closeDialog"
    >
      <div class="guide-container" v-if="showType == 'img'">
        <van-image
          width="100%"
          :src="require('@/assets/image/PC_guide.jpg')"
          fit="contain"
        />
      </div>
      <div class="guide-container" v-if="showType == 'video'">
        <video
          width="100%"
          height="99%"
          controls
          :autoplay="true"
          :src="guideUrl"
        ></video>
      </div>
    </van-dialog>
  </section>
</template>

<script>
import headers from "@/components/header/pc_header";
import publics from "@/mixin/public_mixin";
import home from "@/mixin/home_mixin";
import uploaderCent from "@/components/uploaderBox/index.vue";
import CountryCodeSelector from "@/components/CountryCodeSelector";
import { speecheduUrl } from '@/utils/link'

export default {
  name: "views",
  data() {
    return {
      ermUrl: sessionStorage.getItem("whatAppEwm"),
      datumZZC: false, // 上传视频弹窗控制
      stepActive: 0, //控制流程  很重要这玩意勿搞错  参数0 1 2  表示三步骤
      datumActiveBut: true, //防止連續點擊
      wonderful: false, //成功
      videoYL: false, //預覽
      chooseFrom: 0, //驗證
      from2: {
        name: null, //名字
        school_name: null, //学校
        email: null, //邮箱
        phone: null, //手机
        certificate: null, //证件
        video: null, //视频
      },
      schollName: null, //控制学校是否显示
      newShortZZC: false, //入圍 彈窗
      newShortActive: 1, //1是成功  2是失敗
      choose: 0, //查詢的下標
      from: {
        school_name: null,
        email: null,
        phone: null,
      },
      finalistsBut: true, //防止連續點擊
      userObj: {
        competitionGroup: null, //組別
        score: null, //分數
        studentName: null, //名字
      },
      prefix: "852",
      showGuide: false,
      showType: "",
      guideUrl: '',
    };
  },
  computed: {},
  components: {
    headers,
    uploaderCent,
    CountryCodeSelector,
  },
  mixins: [publics, home],
  watch: {},
  created() {},
  mounted() {},
  methods: {
    closeDialog() {
      const videoElement = document.querySelector(".guide-container video");
      // 暂停视频播放
      videoElement.pause();
    },
    handleShowGuide(type, url) {
      this.showGuide = true;
      this.showType = type;
      this.guideUrl = url;
      this.$nextTick(() => {
        // 动态浏览器视口高度
        const viewportHeight = window.innerHeight;
        const browserInterfaceHeight = window.outerHeight - window.innerHeight;
        const browserWindowHeight = viewportHeight - browserInterfaceHeight;
        const imageContainer = document.querySelector(".guide-container");
        imageContainer.style.height = `${browserWindowHeight}px`;
      });
    },
    handleUploadVideo() {
      this.datumZZC = true;
    },
    whatapp() {
      this.home_mdevInit();
      window.open(this.whatUrl, "_blank");
    },
    aheftBut(e) {
      // this.$dialog
      //   .alert({
      //     title: '提示',
      //     message: '報名未開始',
      //     confirmButtonText: '確認'
      //   })
      //   .then(() => {
      //     // on close
      //   })
      if (this.currentCompetition === '1') {
        this.$router.push("/signup");
      } else {
        window.open(speecheduUrl, '_blank')
      }
    },
    zhangcheng() {
      window.open(this.currentCompetition === '1' ? this.rulesList[0].url : this.rulesList[1].url, "_blank");
      // this.$toast.loading({
      // 	message: '獲取地址中...',
      // 	forbidClick: true,
      // 	duration: 0
      // });

      // let url = 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-07/fe62588d996ef0818389f65f4192086c.pdf';
      // this.$axios({
      //   url: url,
      //   method: 'get',
      //   responseType: 'blob',
      // })
      // .then(response => {
      //   this.$toast.clear();
      //   this.$toast.success('開始下載');
      //   // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
      //   const blob = new Blob([response.data], { type: 'application/pdf' });
      //   const downloadElement = document.createElement('a');
      //   const href = window.URL.createObjectURL(blob); // 创建下载链接
      //   downloadElement.href = href;
      //   downloadElement.download = 'zhangcheng.pdf'; // 文件名
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click(); // 下载
      //   document.body.removeChild(downloadElement); // 下载完成后移除元素
      //   window.URL.revokeObjectURL(href); // 释放占用的内存空间
      // }).catch(error => {
      //   this.$toast.clear();
      //   console.error(error); // 处理异常情况
      // });
    },
    socai() {
      if (this.currentCompetition === '1') {
        this.$router.push("/material");
      } else {
        window.open('https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/image/a9f0bce3cecc7699b4388f119042463e.pdf', "_blank");
      }
    },
    stageBut(e) {
      this.competitionTimeLine.forEach((item, index) => {
        // console.log(item, index);
        if (index == e) {
          item.select = true;
        } else {
          item.select = false;
        }
      });
    },
    datumYZ(e) {
      this.chooseFrom = e;
    },
    lianxi() {
      // 联系我们
      // window.location.href = 'http://wa.me/85291496563'
      window.open("http://wa.me/85291496563");
    },
    // 點擊上傳下一步
    datumBut(e) {
      switch (e) {
        case 0:
          // 下一步 驗證信息
          this.getUser();
          break;
        case 1:
          // this.$toast.clear()
          // 判断学校跟证件
          if (this.from2.school_name == null || this.from2.school_name == "") {
            this.$toast("请输入就讀學校 !");
            return;
          }

          if (this.from2.certificate == null || this.from2.certificate == "") {
            this.$toast("请上傳證件 !");
            return;
          }
          this.stepActive = 2;
          break;
        case 2:
          // 提交啦
          break;
        case 3:
      }
    },
    // 驗證信息
    getUser() {
      let params;
      if (this.chooseFrom == 0) {
        params = {
          studentName: this.from2.name,
          prefix: this.prefix,
          phone: this.from2.phone,
          email: null,
        };
      } else if (this.chooseFrom == 1) {
        params = {
          studentName: this.from2.name,
          prefix: this.prefix,
          phone: null,
          email: this.from2.email,
        };
      }

      if (params.studentName == "" || params.studentName == null) {
        this.$toast("請輸入您的姓名 !");
        return;
      }
      if (this.chooseFrom == 0) {
        // 手機
        if (params.phone == "" || params.phone == null) {
          this.$toast("手機號格式有誤 !");
          return;
        }

        if (!this.validator_phone(params.phone)) {
          this.$toast("手機號格式有誤 !");
          return;
        }
      } else if (this.chooseFrom == 1) {
        // 郵箱
        if (!this.validator_email(params.email)) {
          this.$toast("電郵格式有誤 !");
          return;
        }
      }
      // console.log('上传:', params);
      this.datumActiveBut = false;
      this.$toast.loading();
      this.$axios
        .post(this.$api.getPersonInfo, params)
        .then((response) => {
          this.datumActiveBut = true;
          this.$toast.clear();
          // console.log(response);
          const { data, code, msg } = response.data;
          if (code == 500) {
            this.$toast.fail(msg);
            return;
          }
          const {
            competitionGroup,
            score,
            studentName,
            shortlisted,
            school,
          } = data;
          if (code == 0) {
            this.userObj.competitionGroup = competitionGroup; //組別
            this.userObj.score = score; //分數
            this.userObj.studentName = studentName; //名字
            if (shortlisted) {
              this.from2.school_name = school;
              this.schollName = school == "" ? null : school;
              // 成功
              this.stepActive = 1;
            } else {
              // 失敗
              // this.datumZZC = false; //复赛资料上传弹窗
              this.stepActive = 0; //控制流程  很重要这玩意勿搞错  参数0 1 2  表示三步骤
              this.newShortZZC = true; //入圍 彈窗
              this.newShortActive = 2; //1是成功  2是失敗
            }
          }
        })
        .catch((error) => {
          this.$toast.clear();
          this.datumActiveBut = true;
          //请求失败
          this.$toast.fail("error");
          // console.log(error);
        });
    },
    validator_email(val) {
      const email = val.trim();
      return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(
        email
      );
    },
    validator_phone(val) {
      const phone = val.trim();
      return /^[0-9]*$/.test(phone);
    },
    // 視頻
    uploaderVideoChange(e) {
      // console.log('视频', e);
      this.from2.video = e.vaidoUrl;
      this.videoUrl = e.vaidoUrl;
      this.$toast.clear();
    },
    // 圖像
    uploaderImageChange(e) {
      // console.log('圖像',e);
      this.from2.certificate = e.vaidoUrl;
      this.$toast.clear();
    },
    // 視頻預覽
    videoYLBut() {
      this.videoYL = !this.videoYL;
    },
    //返回上一步
    // getBack() {
    // 	this.stepActive--;
    //   this.from2.video = null
    //   this.videoUrl = null
    // },
    // 提交
    submit() {
      const {
        phone,
        name,
        video,
        email,
        certificate,
        school_name,
      } = this.from2;
      let params = {
        phone, //手機號
        studentName: name, //学生名字
        // videoUrl: 'https://materialv3-1256597606.cos.ap-hongkong.myqc…/dev/2023-09/eb816d9e21e9f18fccea6c461ba2c3dc.mp4', //学生上传的视频内容
        videoUrl: video, //学生上传的视频内容
        email, //學生電郵
        personalID: certificate,
        currentSchool: school_name,
      };

      if (params.videoUrl == null || params.videoUrl == "") {
        this.$toast("請上傳參賽作品 !");
        return;
      }

      this.datumActiveBut = false;
      this.$toast.loading({
        message: "正在提交中...",
        duration: 0,
        forbidClick: true,
      });

      // 本地測試專用
      // this.$axios({
      // 	url: '/api/statistics/competition/update',
      // 	method: 'post',
      // 	data: params
      // })

      // 正式發包
      this.$axios
        .post(this.$api.update, params)
        .then((response) => {
          this.datumActiveBut = true;
          // console.log(response);
          if (response.data.code == 500) {
            this.$toast.fail(response.data.msg);
          }
          if (response.data.code == 0) {
            this.$toast.clear();
            this.wonderful = true;
          }
        })
        .catch((error) => {
          this.datumActiveBut = true;
          //请求失败
          this.$toast.fail("error");
          console.log(error);
        });
    },
    // 上傳成功  相當於初始化
    wonderfulBut() {
      this.wonderful = false;
      this.datumZZC = false;
      this.stepActive = 0;
      let from = {
        name: null,
        school_name: null,
        email: null,
        phone: null,
        certificate: null,
        video: null,
      };
      this.from2 = from;
    },
  },
};
</script>

<style lang="scss" scoped>
.competition-types {
  position: sticky;
  top: 80px;
  width: 100%;
  margin-bottom: 30px;
  background: #d34a00;
  z-index: 99999;
  .type-tabs {
    display: flex;
    justify-content: center;
    gap: 10px;
    .tab-item {
      min-width: 120px;
      padding: 20px 10px;
      color: #fff;
      cursor: pointer;
      font-size: 16px;

      &.active {
        font-weight: 600;
        color: #903300;
        background-image: url("~@/assets/image/hodgepodge/tabbuttom.png");
        background-size: 100% 100%;
        transform: scale(1.2);
      }
    }
  }
}

.awards-box{
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px;
    box-sizing: border-box;
}

.table-wrap {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #fa772f;
  font-size: 16px;
  line-height: 22px;
  font-family: PingFang SC-Semibold,PingFang SC;
  color: #666;

  th {
    border: 1px solid #fa772f;
    padding: 10px;
    font-weight: 600;
  }
  td {
    border: 1px solid #fa772f;
    padding: 10px;
    vertical-align: middle;
  }

  span {
    font-weight: 600;
  }
}

.table-tips {
  margin-top: 20px;
  font-size: 16px;
  font-family: PingFang SC-Regular,PingFang SC;
  font-weight: 400;
  color: #e80202;
  text-align: left;
}

.score-box {
  .left-title {
    margin: 15px 0;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: #fa772f;
  }
  .table-wrap {
    margin-bottom: 25px;
  }
}


.views {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: #ff782f;
  .posters {
    width: 100%;
    display: flex;
    position: relative;

    img {
      width: 100%;
    }

    .posters_ewm {
      position: absolute;
      // width: 1024px;
      left: 0;
      right: 15%;
      bottom: 0;
      top: 0%;
      margin: auto;
      display: flex;
      align-items: flex-start;
      justify-content: right;

      .ewm {
        width: 141px;
        min-height: 184px;
        background: rgba(255, 255, 255, 0.63);
        border-radius: 16px 16px 16px 16px;
        padding: 6px;
        box-sizing: border-box;
        cursor: pointer;

        .ewm2 {
          width: 100%;
          height: 100%;
          background: #fdcf1d;
          border-radius: 12px 12px 12px 12px;
          box-sizing: border-box;
          padding-top: 4px;
          .ewm3 {
            margin: 0 auto;
            width: 122px;
            height: 122px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            margin-bottom: 6px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .ewm3_value {
            width: 100%;
            height: 40px;
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #323232;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: url("~@/assets/image/icon/ewmbut.png") no-repeat;
            background-size: 100% 100%;
            box-shadow: inset 0px 1px 4px 0px rgba(234, 138, 7, 0.48),
              inset 0px -5px 3px 0px rgba(255, 227, 129, 0.57);
            vertical-align: middle; /* 将文本和图片垂直居中对齐 */

            img {
              width: 20px;
              height: 20px;
              margin-right: 4px;
              vertical-align: middle; /* 将图片和文本垂直居中对齐 */
            }
          }
        }
      }
    }
  }

  .organizers {
    max-width: 1240px;
    margin: auto;
    margin-bottom: 54px;
    margin-top: 30px;
    .box_uls {
      width: 964px;
      min-height: 100px;
      margin: auto;
      box-sizing: border-box;
      border-image-source: url("~@/assets/image/hodgepodge/pcbeij.png");
      border-image-slice: 84 10 10 10;
      border-image-width: 67px 9px 9px 9px;
      border-width: 9px;
      padding: 0 10px 10px 10px;
      box-sizing: border-box;

      .box_title {
        width: 100%;
        height: 25px;
        box-sizing: border-box;
        margin: auto;
        position: relative;

        img {
          // width: 100%;
          height: 118.8px;
          margin-top: -6.1%;
          // object-fit: none;
        }
      }
      .box_centent {
        position: relative;
        width: 100%;
        background: #ffffff;
        border-radius: 12px;
      }

      .zhuban {
        padding: 21px 100px 8px 130px;
        box-sizing: border-box;

        .xiaoIconBox {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 27px;

          .xiaoIconList {
            width: 153px;
            height: 64px;
            background: #ffffff;
            border-radius: 12px 12px 12px 12px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .posters_text {
          width: 100%;

          ul {
            width: 100%;

            li {
              width: 100%;
              margin-bottom: 16px;
              display: flex;

              .posters_text_left {
                width: 80px;
                font-size: 14px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 24px;
              }

              .posters_text_right {
                width: calc(100% - 80px);
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  .TimeAxis {
    max-width: 1240px;
    // height: 800px;
    margin: auto;

    ul {
      width: 100%;

      .time_xian {
        width: 100%;
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 22%,
          #ffffff 80%,
          rgba(255, 255, 255, 0) 100%
        );
        display: flex;
        justify-content: space-evenly;

        li {
          width: 167px;
          display: flex;
          align-items: center;

          .xian {
            width: 100%;
            height: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              width: 16px;
              height: 16px;
              background: #ffffff;
              border: 2px solid #ffffff;
              border-radius: 50px;
              cursor: pointer;
            }

            .span_t {
              width: 20px !important;
              height: 20px !important;
              background: #ffe600 !important;
            }

            .select {
              width: auto !important;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              font-size: 14px;
              text-align: center;
              box-sizing: border-box;
              background: #f78040;
              border-radius: 50px;
              border: 1px solid #fff;
              color: #fff;
            }
          }
        }
      }

      .time_xian_top {
        width: 100%;
        height: 86px;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 17px;

        li {
          width: 167px;
          display: flex;
          align-items: center;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          cursor: pointer;
        }

        .select {
          // border-image-source: url("~@/assets/image/icon/qipao.png");
          // border-image-slice: 84 10 10 10;
          // border-image-width: 33px 9px 0px 10px;
          // border-width: 9px;
          // padding: 7px 17px 14px 17px;
          // box-sizing: border-box;
          // font-size: 16px;
          // font-family: PingFang SC-Semibold, PingFang SC;
          // font-weight: 600;
          // color: #903300;
          // line-height: 16px;
        }

        .cshhSelect {
          font-size: 16px;
          font-family: PingFang SC-Semibold, PingFang SC;
          // font-weight: 600;
          color: #ffffff;
          line-height: 19px;
        }

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .time_xian_timeBox {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-top: 17px;

        li {
          width: 167px;
          display: flex;
          align-items: center;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          font-size: 14px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 22px;
          cursor: pointer;
        }
      }

      .time_xian_fuchuang {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        li {
          width: 167px;
          min-height: 136px;

          .list {
            width: 343px;
            min-height: 136px;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
            padding: 12px;
            box-sizing: border-box;
            margin-top: 38px;
            border-radius: 16px;
            background: #ffffff;
            position: relative;

            h1 {
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #f97226;
              line-height: 20px;
              text-align: center;
              margin-bottom: 4px;
              text-align: left;
            }

            .p_xian {
              width: 100%;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 22px;
              text-align: left;
            }

            .butom {
              width: 100%;
              display: flex;
              justify-content: right;
              position: absolute;
              bottom: 0;
              padding: 0 24px 12px 0;
              box-sizing: border-box;

              .buttomBut {
                padding: 10px 12px;
                box-sizing: border-box;
                border-radius: 16px;
                font-size: 12px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                line-height: 14px;
                background: url("~@/assets/image/hodgepodge/buttom.png")
                  no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
              }
            }
          }
        }

        li:last-child {
          .list {
            float: right;
          }
        }
      }
    }
  }

  .viewBoxContent {
    max-width: 1240px;
    margin: auto;
    margin-top: 104px;

    .box_uls {
      width: 964px;
      min-height: 314px;
      margin: auto;
      box-sizing: border-box;
      border-image-source: url("~@/assets/image/hodgepodge/pcbeij.png");
      border-image-slice: 84 10 10 10;
      border-image-width: 67px 9px 9px 9px;
      border-width: 9px;
      padding: 0 10px 10px 10px;
      box-sizing: border-box;

      .box_title {
        width: 100%;
        height: 25px;
        box-sizing: border-box;
        margin: auto;
        position: relative;

        img {
          // width: 100%;
          height: 118.8px;
          margin-top: -6.1%;
          // object-fit: none;
        }
      }
      .box_centent {
        position: relative;
        width: 100%;
        background: #ffffff;
        border-radius: 12px;
        padding: 24px 130px;
        box-sizing: border-box;
      }

      .cszg {
        width: 100%;

        .cszglis {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .cszg_title {
            min-width: 160px;
            height: 36px;
            background: #ff9003;
            box-shadow: 0px 0px 7px 0px rgba(249, 118, 47, 0.3);
            border-radius: 31px 31px 31px 31px;
            border: 2px solid #ffffff;
            padding: 7px 48px;
            box-sizing: border-box;
            font-size: 18px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            margin: auto;
            margin-bottom: 16px;
          }

          .cszg_centent {
            width: 100%;
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            color: #666666;
            line-height: 24px;
            text-align: left;
            margin-bottom: 44px;

            span {
              color: #f97226;
              font-weight: 600;
            }
          }

          .cszg_centent:last-child {
            margin-bottom: 0px;
          }
        }
      }

      .cszg2 {
        .cszg2_list {
          // font-size: 15px;
          // font-family: PingFang SC-Semibold, PingFang SC;
          // font-weight: 600;
          // // color: #f97226;
          // line-height: 22px;
          // text-align: left;
          margin-bottom: 24px;
          ul {
            width: 100%;
            padding-left: 0.48rem;
            box-sizing: border-box;

            li {
              width: 100%;
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              color: #666666;
              line-height: 28px;
              text-align: left;
              list-style: disc;
            }
          }
        }

        .cszg2_uls {
          width: 100%;
          padding-left: 12px;
          box-sizing: border-box;
          margin-bottom: 24px;

          .cszg2_uls_list {
            width: 100%;
            height: 48px;
            border-radius: 0px 0px 0px 0px;
            font-size: 15px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 15px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:nth-child(even) {
              background: #fff3e7;
            }
            &:nth-child(odd) {
              background: #ffe5cc;
            }

            .select_bz {
              min-width: 79px;
              background: #f97226;
              border-radius: 60px 60px 60px 60px;
              padding: 3px 18px;
              box-sizing: border-box;
              background: #f97226;
              border-radius: 60px 60px 60px 60px;
              font-size: 15px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              position: absolute;
              top: 50%;
              left: 0%;
              transform: translate(-14px, -50%);
            }
          }
        }

        .cszg2_remarks {
          width: 100%;
          font-size: 15px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }

      .bxxq {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .bxxq_title {
          min-width: 160px;
          height: 36px;
          background: #ff9003;
          box-shadow: 0px 0px 7px 0px rgba(249, 118, 47, 0.3);
          border-radius: 31px 31px 31px 31px;
          border: 2px solid #ffffff;
          padding: 7px 48px;
          box-sizing: border-box;
          font-size: 18px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin: auto;
          margin-bottom: 16px;
        }

        .bxxq_centent {
          width: 100%;
          margin-bottom: 50px;

          p {
            margin: 20px 0;
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            color: #666666;
            text-align: left;
          }

          ul {
            width: 100%;
            padding-left: 0.48rem;
            box-sizing: border-box;

            li {
              width: 100%;
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              color: #666666;
              line-height: 28px;
              text-align: left;
              list-style: disc;

              span {
                color: #f97226;
                font-weight: 600;
              }

              strong {
                color: #e80202;
                font-weight: 600;
              }
            }
          }

          .a_view {
            margin-top: 25px;
            text-align: center;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #e80202;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .bxxq_centent:last-child {
          margin-bottom: 0px;
        }
      }

      .csjx {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .csjx_title {
          min-width: 160px;
          height: 36px;
          background: #ff9003;
          box-shadow: 0px 0px 7px 0px rgba(249, 118, 47, 0.3);
          border-radius: 31px 31px 31px 31px;
          border: 2px solid #ffffff;
          padding: 7px 48px;
          box-sizing: border-box;
          font-size: 16px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin: auto;
          margin-bottom: 24px;
        }

        // .csjx_centent:last-child {
        //   margin-bottom: 0px;
        // }

        .csjx_centent {
          width: 100%;
          margin-bottom: 45px;

          .csjx_centent_view1 {
            width: 414px;
            min-height: 141px;
            padding: 16px 24px;
            box-sizing: border-box;
            background: #fff6f1;
            border-radius: 16px;
            border: 2px solid #fed011;
            position: relative;
            margin: auto;
            margin-bottom: 24px;

            h1 {
              width: 100%;
              display: flex;
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #f97226;
              line-height: 18px;

              span {
                color: #ff8f03;
                margin-left: 8px;
                font-weight: 500;
                font-size: 14px;
              }
            }

            ul {
              width: 100%;
              margin-top: 20px;
              padding-left: 20px;
              box-sizing: border-box;

              li {
                width: 100%;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 14px;
                text-align: left;
                list-style: disc;
                font-weight: 400;
                margin-bottom: 15px;
              }

              li:last-child {
                margin-bottom: 0px;
              }
            }

            .jinpai {
              width: 90px;
              position: absolute;
              right: -37px;
              bottom: -6px;

              img {
                width: 100%;
              }
            }

            .jiangbei {
              width: 90px;
              position: absolute;
              right: -40px;
              bottom: -6px;

              img {
                width: 100%;
              }
            }
          }

          .csjx_centent_view2 {
            width: 414px;
            background: #fff6f1;
            border-radius: 16px;
            border: 1px solid #fa772f;
            position: relative;
            margin: auto;
            // margin-bottom: 24px;
            display: flex;

            .lists {
              width: 146px;
              height: 98px;
              font-size: 14px;
              font-family: PingFang SC-Semibold, PingFang SC;
              color: #f97226;
              line-height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #fa772f;
            }

            .lists2 {
              width: calc(100% - 146px);

              ul {
                width: 100%;
                height: 100%;
                padding: 16px 24px 16px 48px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;

                li {
                  width: 100%;
                  font-size: 14px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  text-align: left;
                  list-style: disc;
                  font-weight: 400;
                  margin-bottom: 16px;
                }

                li:last-child {
                  margin-bottom: 0px;
                }
              }
            }
          }

          .bo_color {
            background: #fffce8 !important;
            border: 2px solid #fed011 !important;
          }

          .bo_color2 {
            background: #f9fcff !important;
            border: 2px solid #d1e1ee !important;
          }
          .bo_color3 {
            background: #fff4ee !important;
            border: 2px solid #fa772f !important;
          }
        }
      }
    }
  }
  .uploadVideo {
    cursor: pointer;
    position: absolute;
    z-index: 999999;
    top: 16px;
    right: 40px;
    width: 136px;
    height: 48px;
    background: url("~@/assets/image/icon/but.png") no-repeat;
    background-size: 100% 100%;
    font-size: 20px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 15px;
    box-shadow: 0px 4px 9px 0px rgba(244, 86, 5, 0.4);
    border-radius: 80px;
    padding: 15px;
    box-sizing: border-box;
  }

  .datumBox {
    width: 780px;
    // height: 308px;
    padding-top: 45px;
    padding-bottom: 45px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;

    .titleH1 {
      width: 100%;
      font-size: 22px;
      color: #ff5a25;
      font-weight: 600;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .right_icon {
        display: flex;
        align-items: center;
        div:nth-child(3) {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:first-child {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
      }

      .left_icon {
        display: flex;
        align-items: center;
        div:first-child {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(3) {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
      }
    }

    .stepBox {
      width: 55%;
      margin: auto;
      position: relative;

      .stepUls {
        width: 100%;
        height: 30px;
        display: flex;

        .xian {
          width: 50%;
          height: 100%;
          position: relative;
        }

        .active {
          position: relative;
        }

        .active:after {
          content: " ";
          width: 100%;
          height: 2.5px;
          background: #f5f5f5;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        .active2:after {
          content: " ";
          width: 100%;
          height: 2.5px;
          background: #ff5a25;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      ul {
        width: 100%;
        height: 30px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #ff5a25;
          position: relative;

          div {
            position: absolute;
            bottom: -10%;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #cccccc;
          }
        }

        .active {
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: url("~@/assets/image/fusai/dui.png") no-repeat;
          background-size: 100%;
        }
      }
    }

    .stepText {
      width: 62%;
      margin: auto;
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
      }
      div:nth-child(1) {
        margin-left: 5%;
      }

      div:nth-child(2) {
        margin-left: 13%;
      }

      div:nth-child(3) {
        margin-left: 10%;
      }

      .active {
        color: #de2810 !important;
      }
    }

    .datumCentent {
      width: 70%;
      margin: auto;
      margin-top: 30px;

      .tab {
        width: 100%;
        text-align: left;
        margin-bottom: 13px;

        .prompt {
          height: 26px;
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          line-height: 18px;
          // margin-bottom: 12px;
          // padding-left: 13px;
          // box-sizing: border-box;
          display: flex;

          div {
            margin-right: 18px;
            text-align: center;
            line-height: 20px;
            color: #999999;
          }

          .highlight {
            color: #4d4d4d !important;
            position: relative;
          }

          .highlight:after {
            content: " ";
            width: 90%;
            height: 1px;
            background: #4d4d4d;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          span {
            font-size: 9.8px !important;
            color: #de2810;
            margin-left: 5px;
          }
        }

        .lis {
          width: 100%;
          height: 45px;
          line-height: 45px;
          background: #f5f5f5;
          display: flex;
          justify-content: space-between;
          border-radius: 6px 6px 6px 6px;
          // overflow: hidden;
          position: relative;
          .lisLife {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4a4a4a;
            box-sizing: border-box;
            width: 18%;
            padding-left: 15px;

            img {
              width: 16px;
              height: 11px;
              margin-right: 5px;
            }
          }

          .lisRight {
            width: 81%;
            height: 100%;
            input {
              width: 100%;
              height: 100%;
              padding-left: 13px;
              box-sizing: border-box;
              border: none;
              background: #f5f5f5;
              font-size: 13px;
              font-family: PingFang SC-Regular, PingFang SC;
            }
            input::-webkit-input-placeholder {
              color: #999999;
            }
            /* 使用webkit内核的浏览器 */
            input:-moz-placeholder {
              color: #999999;
            }
            /* Firefox版本4-18 */
            input::-moz-placeholder {
              color: #999999;
            }
            /* Firefox版本19+ */
            input:-ms-input-placeholder {
              color: #999999;
            } /* IE浏览器 */
          }
        }

        .promptInput {
          width: 100%;
          height: 50px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 5px;
          margin-bottom: 15px;

          input {
            width: 100%;
            height: 100%;
            font-size: 13px;
            font-family: PingFang SC-Regular, PingFang SC;
            background: #f5f5f5;
            border-radius: 5px;
            border: none;
            padding-left: 13px;
            box-sizing: border-box;
          }

          input::-webkit-input-placeholder {
            color: #999999;
          }
          /* 使用webkit内核的浏览器 */
          input:-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本4-18 */
          input::-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本19+ */
          input:-ms-input-placeholder {
            color: #999999;
          } /* IE浏览器 */
        }

        .promptInput2 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 50px;
          line-height: 50px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 5px;
          margin-bottom: 15px;

          span {
            width: 100%;
            height: 100%;
          }
          .span_tw {
            color: rgb(153, 153, 153) !important;
          }
          .promptInputValue {
            width: 100%;
            height: 100%;
            padding-left: 13px;
            box-sizing: border-box;
            border: none;
            background: #f5f5f5;
            border-radius: 6px 6px 6px 6px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
          }
        }

        .promptInput3 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: #f7f8fa;
          height: 210px;
          border: 3px dotted #f5f5f5;
          position: relative;

          .wonderful {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4d4d4d;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50px;
              margin-bottom: 30px;
            }
          }

          .videoYulan {
            width: 100%;
            height: 100%;
            z-index: 999999;
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            video {
              width: 80%;
              // object-fit: cover;
              height: 100%;
            }

            .guanbu {
              position: absolute;
              top: 5%;
              right: 5%;
              width: 20px;
              height: 20px;
              color: #fff;
              font-size: 12px;
              z-index: 999;
              border-radius: 10px;
              border: 1px solid #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .videoYulan2 {
            // cursor: pointer;
            // color: #ff5a25;
            // margin-left: 10px;
            position: absolute;
            bottom: 8px;
            right: 0px;
            left: 0px;
            margin: auto;
            font-size: 12px;
            color: #ff5a25;
            text-align: center;
            width: 100%;
          }
        }
      }

      .tabTis {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 20px;
        margin-bottom: 50px;

        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }

      .but {
        width: 140px;
        height: 40px;
        background: url("~@/assets/image/but.png") no-repeat;
        background-size: 100%;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        margin: auto;
        margin-top: 20px;
      }

      .but2 {
        width: 140px;
        height: 30px;
        text-align: center;
        font-size: 14px;
        color: #4d4d4d;
        margin: auto;
        margin-top: 10px;
      }
    }
  }
}

::v-deep .CountryCodeSelectorContainer {
  width: 100px;
  .arrow-down {
    width: 12px;
    height: 12px;
  }
}
.viewVideo {
  position: absolute;
  bottom: 30px;
  font-size: 12px;
  color: #4d4d4d;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.reselect {
  position: absolute;
  bottom: 5px;
  font-size: 12px;
  margin: 0 auto;
  color: rgb(235, 196, 120);
  cursor: pointer;
}
.newShortBox {
  width: 600px;
  height: 308px;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;

  .list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .lis {
      width: 100%;
      margin-bottom: 20px;
    }

    .lis:last-child {
      margin-bottom: 0px !important;
    }

    .lisCenten {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .lisText2 {
      display: flex;
      justify-content: space-between;
      width: 65%;
      margin: auto;
      font-size: 14px;

      span {
        color: #ff5a25;
      }
    }

    .ulst {
      width: 70%;
      margin: auto;

      ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 3px 3px 3px 3px;

        li {
          width: 33.3%;
          text-align: center;
          font-size: 14px;
          padding: 0 5px;
        }
        li:nth-child(2) {
          width: 30% !important;
        }
      }

      ul:nth-child(1) {
        background: #ff5a25;
        height: 30px;
        line-height: 30px;

        li {
          height: 100%;
          color: #fff;
        }
      }

      ul:nth-child(2) {
        background: #feffeb;
        font-size: 9.8px !important;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 20px;

        li {
          color: #4e4e4e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .lisText {
      font-size: 15px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4e4e4e;
      line-height: 24px;
    }

    .but {
      width: 140px;
      height: 40px;
      background: url("~@/assets/image/but.png") no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      margin: auto;
    }
  }
}
.show-link {
  color: #f97226;
  font-weight: 400 !important;
}
.show-link:hover {
  font-weight: 600 !important;
  text-decoration: underline;
  cursor: pointer;
}

.guide-container {
  padding: 16px;
  min-height: 600px;
  overflow-y: auto;
}
.van-dialog {
  top: 50%;
  background-color: #000;
}
</style>
